import axiosIns from "@/libs/axios";

const get = async () => {
  return await axiosIns.get(`cargos`)
}

const store = async (data) => {
  return await axiosIns.post('cargos', data)
}

const update = async (id, data) => {
  return await axiosIns.patch(`cargos/${id}`, data)
}

const destroy = async (id) => {
    return await axiosIns.delete(`cargos/${id}`)
}

  
export default {
  get,
  store,
  update,
  destroy,
}
