var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"modal",attrs:{"id":"modal-from-usuario","title":_vm.tituloModal,"no-close-on-backdrop":"","ok-disabled":_vm.submitted,"cancel-disabled":_vm.submitted,"no-close-on-esc":"","ok-title":"Guardar","ok-variant":"primary","cancel-variant":"outline-secondary","cancel-title":"Cerrar","button-size":"sm","size":"xl"},on:{"cancel":function($event){$event.preventDefault();return _vm.cerrarModal.apply(null, arguments)},"close":function($event){$event.preventDefault();return _vm.cerrarModal.apply(null, arguments)},"ok":function($event){$event.preventDefault();return _vm.validar.apply(null, arguments)}},model:{value:(_vm.modalUsuario),callback:function ($$v) {_vm.modalUsuario=$$v},expression:"modalUsuario"}},[_c('b-overlay',{attrs:{"show":_vm.submitted,"no-wrap":"","spinner-variant":"primary","spinner-type":"grow","spinner-small":"","rounded":"lg"}}),_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',{staticClass:"mb-0-5"},[_c('b-col',{staticClass:"mb-lg-1",attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"* NOMBRE","label-for":"nombre"}},[_c('validation-provider',{attrs:{"name":"Nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"nombre","lazy-formatter":"","formatter":_vm.textFormatter,"state":errors.length > 0 ? false : null,"size":"sm"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.validar()}},model:{value:(_vm.form.nombre),callback:function ($$v) {_vm.$set(_vm.form, "nombre", $$v)},expression:"form.nombre"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_vm._l((_vm.errores.nombre),function(error){return _c('small',{key:error,staticClass:"text-danger"},[_vm._v(_vm._s(error))])})]}}])})],1)],1),_c('b-col',{staticClass:"mb-lg-1",attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"* EMAIL","label-for":"correo"}},[_c('validation-provider',{attrs:{"name":"Correo electrónico","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"correo","state":errors.length > 0 ? false : null,"size":"sm"},model:{value:(_vm.form.correo),callback:function ($$v) {_vm.$set(_vm.form, "correo", $$v)},expression:"form.correo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_vm._l((_vm.errores.correo),function(error){return _c('small',{key:error,staticClass:"text-danger"},[_vm._v(_vm._s(error))])})]}}])})],1)],1),(!_vm.form.id)?_c('b-col',{staticClass:"mb-lg-1",attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"* PASSWORD (POR DEFECTO)","label-for":"password"}},[_c('validation-provider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"nombre","value":_vm.form.password,"disabled":"","state":errors.length > 0 ? false : null,"size":"sm"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.validar()}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_vm._l((_vm.errores.password),function(error){return _c('small',{key:error,staticClass:"text-danger"},[_vm._v(_vm._s(error))])})]}}],null,false,2019096963)})],1)],1):_vm._e(),(!_vm.form.id && false)?[_c('b-col',{staticClass:"mb-lg-1",attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"* PASSWORD","label-for":"password"}},[_c('validation-provider',{attrs:{"name":"Password","rules":"required|min:8|password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password","state":errors.length > 0 ? false : null,"type":_vm.passwordFieldType,"name":"login-password","size":"sm","placeholder":"············"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_vm._l((_vm.errores.password),function(error){return _c('small',{key:error,staticClass:"text-danger"},[_vm._v(_vm._s(error))])})]}}],null,false,1247344301)})],1)],1),_c('b-col',{staticClass:"mb-lg-1",attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"* CONFIRMACIÓN PASSWORD","label-for":"password_confirmacion"}},[_c('validation-provider',{attrs:{"name":"Confirmación password","rules":"required|min:8|password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password_confirmacion","state":errors.length > 0 ? false : null,"type":_vm.passwordConfirmacionFieldType,"name":"login-password-confirmacion","size":"sm","placeholder":"············"},model:{value:(_vm.form.password_confirmacion),callback:function ($$v) {_vm.$set(_vm.form, "password_confirmacion", $$v)},expression:"form.password_confirmacion"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordConfirmacionToggleIcon},on:{"click":_vm.togglePasswordConfirmacionVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_vm._l((_vm.errores.password_confirmacion),function(error){return _c('small',{key:error,staticClass:"text-danger"},[_vm._v(_vm._s(error))])})]}}],null,false,2563934919)})],1)],1)]:_vm._e()],2),_c('hr')],1)],1),_c('validation-observer',{ref:"simpleRules2"},[_c('b-form',[_c('h6',{staticClass:"my-1"},[_vm._v("AGREGAR CARGOS")]),_c('b-row',[_c('b-col',{attrs:{"cols":"12","xl":"10"}},[_c('b-row',[_c('b-col',{staticClass:"mb-lg-1",attrs:{"cols":"12","lg":"4"}},[_c('b-form-group',{attrs:{"label":"* SUCURSAL","label-for":"sucursales"}},[_c('validation-provider',{attrs:{"name":"Sucursal","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"style-chooser select-size-sm",attrs:{"label":"nombre","options":_vm.sucursales,"reduce":function (sucursal) { return sucursal; }},model:{value:(_vm.sucursalSeleccionada),callback:function ($$v) {_vm.sucursalSeleccionada=$$v},expression:"sucursalSeleccionada"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-lg-1",attrs:{"cols":"12","lg":"4"}},[_c('b-form-group',{attrs:{"label":"* SEDE","label-for":"sedes"}},[_c('validation-provider',{attrs:{"name":"Sede","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"style-chooser select-size-sm",attrs:{"label":"nombre","options":_vm.sedes,"reduce":function (sede) { return sede; }},model:{value:(_vm.sedeSeleccionada),callback:function ($$v) {_vm.sedeSeleccionada=$$v},expression:"sedeSeleccionada"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-lg-1",attrs:{"cols":"12","lg":"4"}},[_c('b-form-group',{attrs:{"label":"* CARGO","label-for":"cargos"}},[_c('validation-provider',{attrs:{"name":"Cargo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"style-chooser select-size-sm",attrs:{"label":"nombre","options":_vm.cargos,"reduce":function (cargo) { return cargo; }},model:{value:(_vm.cargoSeleccionado),callback:function ($$v) {_vm.cargoSeleccionado=$$v},expression:"cargoSeleccionado"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"2"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{staticClass:"btn btn-secondary btn-sm mb-1 mt-1",attrs:{"type":"button","block":""},on:{"click":_vm.validarAgregarAsignacion}},[_vm._v(" AGREGAR "),_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1)],1)],1)],1)],1)],1)],1),_c('b-overlay',{attrs:{"show":false}},[_c('ag-grid-vue',{staticClass:"ag-theme-balham ag-grid-table-usuarios",attrs:{"gridOptions":_vm.gridOptions,"columnDefs":_vm.columnDefs,"defaultColDef":_vm.defaultColDef,"rowData":_vm.form.asignaciones,"rowSelection":"single","colResizeDefault":"shift","animateRows":false,"floatingFilter":false,"pagination":false,"rowClassRules":_vm.rowClassRules,"alwaysShowVerticalScroll":true,"suppressPaginationPanel":true},on:{"grid-ready":_vm.onGridReady,"rowClicked":function($event){return _vm.onRowClicked($event)}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }