<template>
  <b-modal
    id="modal-from-usuario"
    v-model="modalUsuario"
    ref="modal"
    :title="tituloModal"
    no-close-on-backdrop
    :ok-disabled="submitted"
    :cancel-disabled="submitted"
    no-close-on-esc
    ok-title="Guardar"
    ok-variant="primary"
    cancel-variant="outline-secondary"
    cancel-title="Cerrar"
    button-size="sm"
    size="xl"
    @cancel.prevent="cerrarModal"
    @close.prevent="cerrarModal"
    @ok.prevent="validar"
  >
    <b-overlay
      :show="submitted"
      no-wrap
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="lg"
    />
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row class="mb-0-5">
          <b-col cols="12" lg="6" class="mb-lg-1">
            <b-form-group label="* NOMBRE" label-for="nombre">
              <validation-provider
                #default="{ errors }"
                name="Nombre"
                rules="required"
              >
                <b-form-input
                  id="nombre"
                  v-model="form.nombre"
                  @keyup.enter="validar()"
                  lazy-formatter
                  :formatter="textFormatter"
                  :state="errors.length > 0 ? false : null"
                  size="sm"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.nombre"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" lg="6" class="mb-lg-1">
            <b-form-group label="* EMAIL" label-for="correo">
              <validation-provider
                #default="{ errors }"
                name="Correo electrónico"
                rules="required|email"
              >
                <b-form-input
                  id="correo"
                  v-model="form.correo"
                  :state="errors.length > 0 ? false : null"
                  size="sm"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.correo"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" lg="6" class="mb-lg-1" v-if="!form.id">
            <b-form-group label="* PASSWORD (POR DEFECTO)" label-for="password">
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-form-input
                  id="nombre"
                  :value="form.password"
                  disabled
                  @keyup.enter="validar()"
                  :state="errors.length > 0 ? false : null"
                  size="sm"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.password"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>
          <template v-if="!form.id && false">
            <b-col cols="12" lg="6" class="mb-lg-1">
              <b-form-group label="* PASSWORD" label-for="password">
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required|min:8|password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="password"
                      v-model="form.password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      size="sm"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-for="error in errores.password"
                    :key="error"
                    class="text-danger"
                    >{{ error }}</small
                  >
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" lg="6" class="mb-lg-1">
              <b-form-group
                label="* CONFIRMACIÓN PASSWORD"
                label-for="password_confirmacion"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Confirmación password"
                  rules="required|min:8|password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="password_confirmacion"
                      v-model="form.password_confirmacion"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordConfirmacionFieldType"
                      name="login-password-confirmacion"
                      size="sm"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordConfirmacionToggleIcon"
                        @click="togglePasswordConfirmacionVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-for="error in errores.password_confirmacion"
                    :key="error"
                    class="text-danger"
                    >{{ error }}</small
                  >
                </validation-provider>
              </b-form-group>
            </b-col>
          </template>
        </b-row>
        <hr />
      </b-form>
    </validation-observer>

    <validation-observer ref="simpleRules2">
      <b-form>
        <h6 class="my-1">AGREGAR CARGOS</h6>

        <b-row>
          <b-col cols="12" xl="10">
            <b-row>
              <b-col cols="12" lg="4" class="mb-lg-1">
                <b-form-group label="* SUCURSAL" label-for="sucursales">
                  <validation-provider
                    #default="{ errors }"
                    name="Sucursal"
                    rules="required"
                  >
                    <v-select
                      v-model="sucursalSeleccionada"
                      class="style-chooser select-size-sm"
                      label="nombre"
                      :options="sucursales"
                      :reduce="(sucursal) => sucursal"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="12" lg="4" class="mb-lg-1">
                <b-form-group label="* SEDE" label-for="sedes">
                  <validation-provider
                    #default="{ errors }"
                    name="Sede"
                    rules="required"
                  >
                    <v-select
                      v-model="sedeSeleccionada"
                      class="style-chooser select-size-sm"
                      label="nombre"
                      :options="sedes"
                      :reduce="(sede) => sede"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="12" lg="4" class="mb-lg-1">
                <b-form-group label="* CARGO" label-for="cargos">
                  <validation-provider
                    #default="{ errors }"
                    name="Cargo"
                    rules="required"
                  >
                    <v-select
                      v-model="cargoSeleccionado"
                      class="style-chooser select-size-sm"
                      label="nombre"
                      :options="cargos"
                      :reduce="(cargo) => cargo"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12" xl="2">
            <b-row>
              <b-col cols="12">
                <b-button
                  type="button"
                  block
                  class="btn btn-secondary btn-sm mb-1 mt-1"
                  @click="validarAgregarAsignacion"
                >
                  AGREGAR
                  <feather-icon icon="PlusIcon" />
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <b-overlay :show="false">
      <ag-grid-vue
        :gridOptions="gridOptions"
        class="ag-theme-balham ag-grid-table-usuarios"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="form.asignaciones"
        @grid-ready="onGridReady"
        rowSelection="single"
        colResizeDefault="shift"
        :animateRows="false"
        :floatingFilter="false"
        :pagination="false"
        :rowClassRules="rowClassRules"
        :alwaysShowVerticalScroll="true"
        :suppressPaginationPanel="true"
        @rowClicked="onRowClicked($event)"
      >
      </ag-grid-vue>
    </b-overlay>
  </b-modal>
</template>
<script>
import store from "@/store/index";
import { AgGridVue } from "ag-grid-vue";
import UsuarioServices from "../services/index";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import {
  BModal,
  BButton,
  VBModal,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BForm,
  BOverlay,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { required, password } from "@validations";
import SucursalServices from "@/modules/configuracion/sucursal/services/index.js";
import CargoServices from "@/modules/configuracion/cargo/services/index.js";

export default {
  components: {
    BButton,
    BModal,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    BOverlay,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    vSelect,
    AgGridVue,
  },
  props: ["item"],
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      form: {
        id: null,
        nombre: "",
        correo: "",
        password: "",
        // password_confirmacion: "",
        asignaciones: [],
      },
      sucursales: [],
      cargos: [],
      sucursalSeleccionada: [],
      sedeSeleccionada: [],
      cargoSeleccionado: [],
      required,
      submitted: false,
      errores: [],
      modalUsuario: false,
      passwordFieldType: "password",
      passwordConfirmacionFieldType: "password",
      // TODO: grid
      gridApi: null,
      gridOptions: {
        suppressPropertyNamesCheck: true,
        localeText: {
          noRowsToShow: "No hay filas para mostrar",
          contains: "Contiene",
          notContains: "No Contiene",
          equals: "Es Igual",
          notEqual: "No es Igual",
          startsWith: "Comienza con",
          endsWith: "Termina con",
          filterOoo: "Filtral...",
        },
      },
      columnDefs: [
        {
          headerName: "SUCURSAL",
          field: "sucursal.nombre",
          filter: false,
          minWidth: 300,
          // maxWidth: 300,
        },
        {
          headerName: "SEDE",
          field: "sede.nombre",
          filter: false,
          minWidth: 300,
          // maxWidth: 300,
        },
        {
          headerName: "CARGO",
          field: "cargo.nombre",
          filter: false,
          minWidth: 300,
          // maxWidth: 300,
        },
        {
          headerName: "ACCION",
          filter: false,
          minWidth: 200,
          maxWidth: 200,
          cellRenderer: function (param) {
            if (param.node.rowPinned) {
              return undefined;
            } else {
              return `<button type="button" data-action-type="remove" class="btn btn-flat-danger btn-sm">Eliminar &times;</button>`;
            }
          },
        },
      ],
      rowClassRules: null,
      gridApi: null,
      selectedRows: [],
      defaultColDef: {
        sortable: false,
        //editable: false,
        floatingFilter: true,
        resizable: true,
        suppressMenu: true,
      },
    };
  },
  created() {
    this.password = this.mixing.passwordPorDefecto;
  },
  computed: {
    tituloModal() {
      return this.form.id ? "EDITAR USUARIO" : "NUEVO USUARIO";
    },
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    passwordConfirmacionToggleIcon() {
      return this.passwordConfirmacionFieldType === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
    sedes() {
      return this.sucursalSeleccionada && this.sucursalSeleccionada.sedes
        ? this.sucursalSeleccionada.sedes.map((sede) => {
            return {
              id: sede.id,
              nombre: `${sede.municipio.departamento.nombre}
              - ${sede.municipio.nombre}
              - ${sede.numero_sede}`,
            };
          })
        : [];
    },
  },
  watch: {
    "form.nombre": function () {
      this.errores.nombre = [];
    },
    "form.correo": function () {
      this.errores.correo = [];
    },
    "form.password": function () {
      this.errores.password = [];
    },
    "form.password_confirmacion": function () {
      this.errores.password_confirmacion = [];
    },
    sucursalSeleccionada: function (value) {
      if (!value) {
        this.sedeSeleccionada = [];
      }
    },
  },
  methods: {
    togglePasswordVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
    togglePasswordConfirmacionVisibility() {
      this.passwordConfirmacionFieldType =
        this.passwordConfirmacionFieldType === "password" ? "text" : "password";
    },
    validar() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          if (!this.submitted) {
            this.submitted = true;
            if (this.form.id) {
              this.actualizar();
            } else {
              this.registrar();
            }
          }
        }
      });
    },
    validarAgregarAsignacion() {
      this.$refs.simpleRules2.validate().then((success) => {
        if (success) {
          const nuevaAsignacion = {
            cargo: this.cargoSeleccionado,
            sede: this.sedeSeleccionada,
            sucursal: this.sucursalSeleccionada,
          };

          const existeAsignacion =
            this.form.asignaciones.findIndex(
              (asignacion) => asignacion.sede.id == nuevaAsignacion.sede.id
            ) !== -1;

          if (existeAsignacion) {
            this.$bvToast.toast(
              `Ya esta asignando un cargo al usuario en la sede: ${nuevaAsignacion.sede.nombre}`,
              {
                title: "ERROR!",
                variant: "danger",
                solid: false,
              }
            );
          } else {
            this.form.asignaciones.push(nuevaAsignacion);

            this.sucursalSeleccionada = [];
            this.sedeSeleccionada = [];
            this.cargoSeleccionado = [];
          }
          this.$refs.simpleRules2.reset();
        }
      });
    },
    abrir(accion, data = []) {
      this.getSurcusales();
      this.getCargos();
      switch (accion) {
        case "registrar": {
          this.form.id = null;
          this.form.nombre = "";
          this.form.correo = "";
          this.form.password = this.mixing.passwordPorDefecto;
          // this.form.password_confirmacion = "";
          this.form.asignaciones = [];
          this.modalUsuario = true;
          break;
        }
        case "actualizar": {
          this.form.id = data["id"];
          this.form.nombre = data["nombre"];
          this.form.correo = data["correo"];
          this.form.asignaciones = data["cargos_sedes_usuarios"].map(
            (cargoSedeUsuario) => {
              const sucursal = {
                ...cargoSedeUsuario.sede.sucursal,
                sede_principal: cargoSedeUsuario.sede.sucursal.sedes.find(
                  (sede) => sede.numero_sede == 0
                ),
              };

              const asignacion = {
                id: cargoSedeUsuario.id,
                sucursal: {
                  id: sucursal.id,
                  nombre: `${sucursal.sede_principal.municipio.departamento.nombre} - ${sucursal.sede_principal.municipio.nombre} - ${sucursal.numero_sucursal}`,
                },
                cargo: cargoSedeUsuario.cargo,
                sede: {
                  id: cargoSedeUsuario.sede.id,
                  nombre: `${cargoSedeUsuario.sede.municipio.departamento.nombre} - ${cargoSedeUsuario.sede.municipio.nombre} - ${cargoSedeUsuario.sede.numero_sede}`,
                },
              };

              return asignacion;
            }
          );
          this.modalUsuario = true;
          break;
        }
      }
      this.errores = [];
    },
    cerrarModal() {
      this.form.id = null;
      this.modalUsuario = false;
      this.limpiar();
    },
    actualizarUsuario(item) {
      this.form.id = null;
      this.modalUsuario = false;
      this.limpiar();
      this.$emit("actualizarUsuario", item);
      this.submitted = false;
    },
    // SERVICES
    async registrar() {
      try {
        const response = await UsuarioServices.store(this.form);
        store
          .dispatch("auth/attempt", localStorage.getItem("token"))
          .then(() => {});
        this.actualizarUsuario(response.data);
        this.errores = [];
      } catch (err) {
        this.submitted = false;
        this.errores = err.response.data.errors;
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
    async actualizar() {
      try {
        const response = await UsuarioServices.update(this.form.id, this.form);
        store
          .dispatch("auth/attempt", localStorage.getItem("token"))
          .then(() => {});
        this.actualizarUsuario(response.data);
        this.errores = [];
      } catch (err) {
        this.submitted = false;
        this.errores = err.response.data.errors;
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
    limpiar() {
      this.form.id = null;
      this.form.nombre = "";
      this.form.correo = "";
      this.form.password = "";
      this.form.passwordConfirmacion = "";
      this.form.asignaciones = [];
      this.passwordFieldType = "password";
      this.passwordConfirmacionFieldType = "password";
      this.sucursalSeleccionada = [];
      this.sedeSeleccionada = [];
      this.cargoSeleccionado = [];
      this.$refs.simpleRules.reset();
      this.modalUsuario = false;
    },
    textFormatter(value) {
      return value.toUpperCase();
    },
    numberFormatter(value) {
      return (value * 1).toFixed(2);
    },
    async getSurcusales() {
      try {
        const response = await SucursalServices.get();

        this.sucursales = response.data.map((sucursal) => {
          return {
            id: sucursal.id,
            nombre: `${sucursal.sede_principal.municipio.departamento.nombre} - ${sucursal.sede_principal.municipio.nombre} - ${sucursal.numero_sucursal}`,
            sedes: sucursal.sedes,
          };
        });
      } catch (error) {
        console.error(error);
      }
    },
    async getCargos() {
      try {
        const response = await CargoServices.get();
        this.cargos = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    onRowClicked(e) {
      if (e.event.target !== undefined) {
        let rowIndex = e.node.id;
        let actionType = e.event.target.getAttribute("data-action-type");
        switch (actionType) {
          case "remove":
            return this.remover(rowIndex);
        }
      }
    },
    onGridReady(params) {
      params.api.sizeColumnsToFit();
    },
    onFirstDataRendered(params) {
      params.api.sizeColumnsToFit();
    },
    remover(index) {
      this.form.asignaciones.splice(index, 1);
    },
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.vs__deselect {
  fill: rgb(255 255 255);
}
</style>